import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "components/Loader";
import { NoData } from "components/NoData";
import { Search } from "components/Search";
import { Status } from "components/Status";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  FontIcon,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableHeader,
} from "components/core-components";
import React, { useEffect, useState } from "react";
import { getCustomers } from "store/ManageCustomers/customerSlice";
import { DeleteHoldBill, FetchAllHoldBills, UpdateWholeCart } from "store/POS";
import { updatePageNo } from "store/commonSlice";
import {
  BUTTON_CONSTANTS,
  GENERAL_CONSTANTS,
  HOLD_BILL_CONSTANTS,
  IGetBillItem,
  POS_CART_CONSTANTS,
  STATUSES,
  ScrollToTop,
  TOAST_CONSTANTS,
  Toast,
  formatDateTime,
  useAppDispatch,
  useAppSelector,
  usePagination,
} from "utils";
import { ConfirmModal } from "components/ConfirmModal";

interface IProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const HoldBillModal = ({ modal, setModal }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    auth: { permissions: permissionData },
    branch: { selectedStore },
    holdBill: { holdBills: holdBillData, status: holdBillStatus },
    common: { current_page, total_items, total_pages, page_size },
    cart: { cart },
  } = useAppSelector((state) => state.root);

  const [query, setQuery] = useState(GENERAL_CONSTANTS.EMPTY_STRING);

  const [selectedHoldBill, setSelectedHoldBill] = useState<IGetBillItem | null>(
    null
  );

  const holdBillPermission = permissionData.find(
    (p) => p.module_name === HOLD_BILL_CONSTANTS.PERMISSION_NAME
  );

  const update_page = (pageNo: number) => {
    if (modal) {
      ScrollToTop();
      dispatch(getCustomers(true));
      dispatch(FetchAllHoldBills({ pageNo, query, store: selectedStore }));
    }
  };

  const {
    resetState,
    prevClickHandler,
    nextClickHandler,
    pageChangeHandler,
  } = usePagination({
    update_page,
    current_page,
    total_items,
    total_pages,
    page_size,
    query,
  });

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  return (
    <>
      <Modal
        modalSize={GENERAL_CONSTANTS.EXTRA_LARGE}
        centered={false}
        title={POS_CART_CONSTANTS.HOLD_BILL}
        modalClassNames="w-100"
        showModal={modal}
        onClickHandler={() => {
          dispatch(updatePageNo(1));
          setModal(false);
        }}
      >
        <Container>
          <div className="col-12">
            <Card>
              <CardHeader>
                <div className="col-6 offset-6">
                  <Search placeholder="Search ..." setQuery={setQuery} />
                </div>
              </CardHeader>
              <CardBody>
                <Table>
                  <TableHeader cols={HOLD_BILL_CONSTANTS.TABLE_HEADER} />
                  <TableBody>
                    {holdBillStatus === STATUSES.LOADING ? (
                      <tr>
                        <td
                          colSpan={HOLD_BILL_CONSTANTS.TABLE_HEADER.length}
                          className="text-center"
                        >
                          <Loader />
                        </td>
                      </tr>
                    ) : holdBillData.length > 0 ? (
                      holdBillData.map((holdBill: IGetBillItem, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              {page_size * (current_page - 1) + index + 1}
                            </td>
                            <td>{holdBill.id}</td>
                            <td>{holdBill?.customer?.name ?? "N/A"}</td>
                            <td>₹{holdBill.bill_amount}</td>
                            <td>
                              {formatDateTime(holdBill.created_at.toString())}
                            </td>
                            <td>
                              <Status is_active={holdBill.is_active} />
                            </td>
                            <td>
                              <Button
                                text={<FontIcon icon={faPenToSquare} />}
                                isDisabled={!holdBillPermission?.perm_edit}
                                type={BUTTON_CONSTANTS.BUTTON}
                                btnClassNames={"btn btn-info btn-sm mr-2"}
                                onClickHandler={() => {
                                  if (
                                    cart.cartItems &&
                                    cart.cartItems.length > 0
                                  ) {
                                    Toast(
                                      HOLD_BILL_CONSTANTS.LOAD_BILL_MESSAGE,
                                      TOAST_CONSTANTS.WARNING
                                    );
                                  } else {
                                    dispatch(UpdateWholeCart(holdBill));
                                    setModal(false);
                                  }
                                }}
                              />
                              {holdBill.is_active ? (
                                <Button
                                  text={<FontIcon icon={faTrash} />}
                                  isDisabled={!holdBillPermission?.perm_delete}
                                  type={BUTTON_CONSTANTS.BUTTON}
                                  btnClassNames={"btn btn-danger btn-sm"}
                                  onClickHandler={() => {
                                    setSelectedHoldBill(holdBill);
                                    setConfirmModal(true);
                                  }}
                                />
                              ) : null}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoData len={HOLD_BILL_CONSTANTS.TABLE_HEADER.length} />
                    )}
                  </TableBody>
                </Table>
              </CardBody>
              <CardFooter>
                <Pagination
                  pageSize={page_size}
                  totalItems={total_items}
                  totalPages={total_pages}
                  currentPage={current_page}
                  prevClickHandler={prevClickHandler}
                  nextClickHandler={nextClickHandler}
                  pageChangeHandler={pageChangeHandler}
                />
              </CardFooter>
            </Card>
          </div>
        </Container>
      </Modal>
      <ConfirmModal
        modal={confirmModal}
        backdrop={false}
        setModal={setConfirmModal}
        title={GENERAL_CONSTANTS.EMPTY_STRING}
        message={HOLD_BILL_CONSTANTS.DELETE_HOLD_BILL_MESSAGE}
        confirmClick={() => {
          dispatch(DeleteHoldBill(selectedHoldBill?.id));
          setConfirmModal(false);
        }}
      />
    </>
  );
};

export { HoldBillModal };
