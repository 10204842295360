import { createSlice } from "@reduxjs/toolkit";
import {
  ACCESS_CONSTANTS,
  FORM_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
} from "utils/constants";
import {
  delProduct,
  delProductBatchMapping,
  delProductNutritionMapping,
  delProductTaxMapping,
  getActiveBarcodeType,
  getActiveBranch,
  getActiveBrand,
  getActiveCategory,
  getActiveDiscountType,
  getActiveHSNCode,
  getActiveProduct,
  getActiveProductTax,
  getActiveSubCategory,
  getActiveUOM,
  getProduct,
  getProductFormSupportingData,
  getProductNutrients,
  getScannedProduct,
  getSpecificProduct,
  getStoreSpecificProduct,
  setProduct,
  updateProduct,
} from "apis/restApis";
import {
  ProductType,
  Toast,
  constructMSQ,
  constructStorePrices,
  filterNullElements,
  generateNutritionDetails,
  makeid,
  transformProduct,
} from "utils";
import {
  setcommonError,
  setCommonStatus,
  setControllerState,
  updateValues,
} from "store/commonSlice";
import { AppDispatch, RootState } from "store/store";
import { AddPOSProduct, AddProduct } from "store/POS/cartSlice";
import { NavigateFunction } from "react-router-dom";
import { FormikHelpers, FormikProps } from "formik";
import { getAllProductTax } from "./productTaxSlice";
import { getAllBranch } from "store/ManageBranches/branchSlice";
import { getAllHSNCodes } from "store/hsnCodesSlice";
import { getAllBarcodeType } from "./barcodeSlice";
import { getAllBrands } from "./productBrandSlice";
import { getAllProductCategory } from "./productCategorySlice";
import { getAllProductSubCategory } from "./productSubCategorySlice";
import { getAllUOMs } from "store/uomSlice";
import { getAllDiscountType } from "./ManageDiscounts/discountTypeSlice";
import { getAllNutrients } from "./nutrientSlice";
import { getIndividualProductGroup } from "./productGroupSlice";
import axios from "axios";
import { getAllProductTaste } from "./tasteSlice";

const initialState = {
  products: [] as ProductType[],
  productToEdit: {} as ProductType,
  batchOptions: [] as any,
  status: STATUSES.IDLE as string,
  error: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    getAllProducts(state, action) {
      state.products = action.payload;
    },
    addProduct(state, action) {
      state.products.unshift(action.payload);
    },
    appendProducts(state, action) {
      state.products = [...state.products, ...action.payload];
    },
    removeNPreviousProducts(state, action) {
      state.products = state.products.splice(0, action.payload);
    },
    removeProduct(state, action) {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
    editProduct(state, action) {
      const { id } = action.payload;
      const index = state.products.findIndex((product) => product.id === id);
      if (index !== -1) {
        state.products[index] = action.payload;
      }
    },
    clearProducts(state) {
      state.products = [] as ProductType[];
    },

    setProductToEdit(state, action) {
      state.productToEdit = action.payload;
    },
    clearProductToEdit(state) {
      state.productToEdit = {} as ProductType;
    },

    setBatchOptions(state, action) {
      state.batchOptions = action.payload;
    },
    clearBatchOptions(state) {
      state.batchOptions = null;
    },

    setProductStatus(state, action) {
      state.status = action.payload;
    },
    setProductError(state, action) {
      state.error = action.payload;
    },
    resetProductState: () => initialState,
  },
});

export const {
  getAllProducts,
  addProduct,
  removeProduct,
  editProduct,
  clearProducts,

  appendProducts,
  removeNPreviousProducts,

  setProductToEdit,
  clearProductToEdit,

  setBatchOptions,
  clearBatchOptions,

  setProductStatus,
  setProductError,
  resetProductState,
} = productSlice.actions;

export default productSlice.reducer;

export function getProducts(
  active?: boolean,
  pageNo = 1,
  query = "",
  setQuery?: React.Dispatch<React.SetStateAction<string>>
) {
  return async function getProductsThunk(dispatch) {
    dispatch(setProductStatus(STATUSES.LOADING));
    active
      ? getActiveProduct(pageNo, query)
          .then((response: any) => {
            dispatch(getAllProducts(response.result));
          })
          .catch((error) => {
            dispatch(setProductError(error.message));
            dispatch(setProductStatus(STATUSES.ERROR));
          })
          .finally(() => {
            dispatch(setProductStatus(STATUSES.IDLE));
          })
      : getProduct(pageNo, query)
          .then((response: any) => {
            if (response.result.count === 0) {
              Toast("No such Product exists", "warning");
            }
            dispatch(updateValues(response));
            dispatch(getAllProducts(response.result.results));
            // setQuery && setQuery("");
          })
          .catch((error) => {
            dispatch(setProductError(error.message));
            dispatch(setProductStatus(STATUSES.ERROR));
          })
          .finally(() => {
            dispatch(setProductStatus(STATUSES.IDLE));
          });
  };
}

interface FetchOptionProps {
  productData?: any;
  editOptions?: {
    edit: boolean;
    setInitialData?: React.Dispatch<
      React.SetStateAction<{
        ingredients: any[];
        tax: any[];
      }>
    >;
  };
}

export function FetchProductFormOptionsData({
  productData,
  editOptions,
}: FetchOptionProps) {
  return async function FetchFormOptionsDataThunk(dispatch, getState) {
    dispatch(setProductStatus(STATUSES.LOADING));
    Promise.all([
      getActiveProductTax(),
      getActiveBranch(),
      getActiveHSNCode(),
      getActiveBarcodeType(),
      getActiveBrand(),
      getActiveCategory(),
      getActiveSubCategory(),
      getActiveUOM(),
      getActiveDiscountType(),
      getProductNutrients(true),
    ])
      .then(
        axios.spread(
          (
            tax: any,
            branch: any,
            hsn: any,
            barcodeTypes: any,
            brands: any,
            categories: any,
            subCategories: any,
            uoms: any,
            discountTypes: any,
            nutrients: any
          ) => {
            if (editOptions.edit) {
              const editedValues = {
                print_name: productData?.print_name,
                product_code: productData?.product_code,
                expiration_days: productData?.expiration_days,
                net_weight: productData?.net_weight,

                category:
                  productData && productData.category
                    ? categories.result
                        .filter((item) => item.id === productData.category)
                        .map((res) => ({
                          value: res.id,
                          label: res.category_name,
                        }))
                    : "",

                sub_category:
                  productData && productData.sub_category
                    ? subCategories.result
                        .filter((item) => item.id === productData.sub_category)
                        .map((res) => ({
                          value: res.id,
                          label: res.sub_category_name,
                        }))
                    : "",

                hsn_code:
                  productData && productData.hsn_code
                    ? [
                        {
                          value: productData.hsn_code.id,
                          label: productData.hsn_code.hsn_code,
                        },
                      ]
                    : "",

                brand:
                  productData && productData.brand
                    ? brands.result
                        .filter((item) => item.id === productData.brand)
                        .map((res) => ({
                          value: res.id,
                          label: res.brand_name,
                        }))
                    : "",

                description:
                  productData && productData.description
                    ? productData.description
                    : "",

                uom:
                  productData && productData.uom
                    ? [
                        {
                          value: productData.uom.id,
                          label: productData.uom.uom_name,
                        },
                      ]
                    : "",

                selling_uom:
                  productData && productData.selling_uom
                    ? [
                        {
                          value: productData.selling_uom.id,
                          label: productData.selling_uom.uom_name,
                        },
                      ]
                    : "",

                tax:
                  productData?.tax?.length > 0
                    ? productData.tax.map((item) => ({
                        ...item,
                        id: item.tax.id,
                        value: item.tax.id,
                        label: item.tax.tax_name,
                      }))
                    : [],

                ingredients: productData?.ingredients,

                nutrition_details: generateNutritionDetails(
                  productData,
                  nutrients.result,
                  uoms.result
                ),

                barcode_type:
                  productData &&
                  productData.barcode_type &&
                  barcodeTypes.result
                    .filter((bar) => bar.id === productData.barcode_type)
                    .map((res) => ({
                      value: res.id,
                      label: res.barcode_type,
                    })),

                price:
                  productData?.price?.length > 0
                    ? productData.price.map((price) => ({
                        ...price,
                        batch_name: price.batch_name,
                        store_prices:
                          price &&
                          price.store_prices &&
                          price.store_prices.length > 0 &&
                          price.store_prices.map((store) => ({
                            ...store,
                            store: branch.result
                              .filter((branch) => branch.id === store.store)
                              .map((res) => res.store_name)[0],
                            mrp: store.mrp,
                            selling_discount: store.selling_discount,
                            selling_price: store.selling_price,
                            minimum_selling_quantity:
                              store.minimum_selling_quantity,
                            selling_discount_type: discountTypes.result
                              .filter(
                                (dis) => dis.id === store.selling_discount_type
                              )
                              .map((res) => ({
                                value: res.id,
                                label: res.type,
                              })),
                            ceil: store.ceil ? true : false,
                          })),
                      }))
                    : [
                        {
                          batch_name: `Batch ${makeid(10)}`,
                          store_prices: branch.result.map((branch) => ({
                            store: branch.store_name,
                            mrp: "",
                            selling_discount: "",
                            selling_price: "",
                            minimum_selling_quantity: "",
                            selling_discount_type: "",
                            ceil: true,
                          })),
                        },
                      ],

                minimum_selling_quantity:
                  productData?.minimum_selling_quantity?.length > 0
                    ? productData.minimum_selling_quantity.map((msq) => ({
                        ...msq,
                        store: msq.store.store_name,
                        days: {
                          Mon: msq.monday,
                          Tue: msq.tuesday,
                          Wed: msq.wednesday,
                          Thu: msq.thursday,
                          Fri: msq.friday,
                          Sat: msq.saturday,
                          Sun: msq.sunday,
                        },
                      }))
                    : branch.result.map((branch) => ({
                        store: branch.store_name,
                        days: FORM_CONSTANTS.DAYS_OF_WEEK.reduce((obj, day) => {
                          obj[day] = 100;
                          return obj;
                        }, {}),
                      })),

                is_active: productData?.is_active
                  ? [
                      {
                        value: productData.is_active ? 1 : 0,
                        label: productData.is_active ? "Active" : "Inactive",
                      },
                    ]
                  : [{ value: 1, label: "Active" }],
              };
              editOptions.setInitialData({
                ingredients: editedValues.ingredients,
                tax: editedValues.tax,
              });
              dispatch(setProductToEdit(editedValues));
            }
            dispatch(getAllProductTax(tax.result));
            dispatch(getAllBranch(branch.result));
            dispatch(getAllHSNCodes(hsn.result));
            dispatch(getAllBarcodeType(barcodeTypes.result));
            dispatch(getAllBrands(brands.result));
            dispatch(getAllProductCategory(categories.result));
            dispatch(getAllProductSubCategory(subCategories.result));
            dispatch(getAllUOMs(uoms.result));
            dispatch(getAllDiscountType(discountTypes.result));
            dispatch(getAllNutrients(nutrients.result));
          }
        )
      )
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
        dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "addProduct" }));
      });
  };
}

interface FetchSupportingDataProps {
  id: number;
  editOptions?: {
    edit: boolean;
    setInitialData?: React.Dispatch<
      React.SetStateAction<{
        ingredients: any[];
        tax: any[];
        taste: any[];
      }>
    >;
    extraAction?: (value) => void;
    navigate?: NavigateFunction;
    props?: FormikProps<any>;
  };
}

export function FetchSupportingData({
  id,
  editOptions,
}: FetchSupportingDataProps) {
  return async function FetchSupportingDataThunk(dispatch, getState) {
    const {
      root: {
        auth: { permissions },
      },
    }: RootState = getState();

    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "addProduct" }));
    getProductFormSupportingData(id)
      .then((response: any) => {
        if (!editOptions.extraAction) {
          const avaryaBrandEditAccess = permissions.find(
            (item) => item.module_name === "Avarya Product Filter"
          );
          if (
            response?.result?.product_data?.response?.result?.product_data
              ?.brand !== null &&
            response?.result?.product_data?.brand?.id ===
              ACCESS_CONSTANTS.AVARYA_BRAND_ID
          ) {
            if (!avaryaBrandEditAccess?.perm_edit) {
              Toast("You do not have access to edit Avarya Products", "error");
              editOptions.navigate("/admin/products");
            }
          }
        }

        dispatch(getAllProductTax(response.result.tax_data || []));
        dispatch(getAllProductTaste(response.result.taste_data || []));
        dispatch(getAllBranch(response.result.store_data || []));
        dispatch(getAllHSNCodes(response.result.hsn_data || []));
        dispatch(getAllBarcodeType(response.result.barcode_type_data || []));
        dispatch(getAllBrands(response.result.brands_data || []));
        dispatch(getAllProductCategory(response.result.category_data || []));
        dispatch(
          getAllProductSubCategory(response.result.sub_category_data || [])
        );
        dispatch(getAllUOMs(response.result.uom_data || []));
        dispatch(getAllDiscountType(response.result.discount_type_data || []));
        dispatch(getAllNutrients(response.result.nutrients_data || []));

        if (editOptions.edit) {
          const editedValues = {
            print_name: response.result.product_data?.print_name,
            product_code: response.result.product_data?.product_code,
            expiration_days: response.result.product_data?.expiration_days,
            net_weight: response.result.product_data?.net_weight,

            category:
              response.result.product_data &&
              response.result.product_data.category
                ? [
                    {
                      value: response.result.product_data.category.id,
                      label:
                        response.result.product_data.category.category_name,
                    },
                  ]
                : "",

            sub_category:
              response.result.product_data &&
              response.result.product_data.sub_category
                ? [
                    {
                      value: response.result.product_data.sub_category.id,
                      label:
                        response.result.product_data.sub_category
                          .sub_category_name,
                    },
                  ]
                : "",

            hsn_code:
              response.result.product_data &&
              response.result.product_data.hsn_code
                ? [
                    {
                      value: response.result.product_data.hsn_code.id,
                      label: response.result.product_data.hsn_code.hsn_code,
                    },
                  ]
                : "",

            brand:
              response.result.product_data && response.result.product_data.brand
                ? [
                    {
                      value: response.result.product_data.brand.id,
                      label: response.result.product_data.brand.brand_name,
                    },
                  ]
                : "",

            description:
              response.result.product_data &&
              response.result.product_data.description
                ? response.result.product_data.description
                : "",

            uom:
              response.result.product_data && response.result.product_data.uom
                ? [
                    {
                      code: response.result.product_data.uom.uom_code,
                      value: response.result.product_data.uom.id,
                      label: response.result.product_data.uom.uom_name,
                    },
                  ]
                : "",

            selling_uom:
              response.result.product_data &&
              response.result.product_data.selling_uom
                ? [
                    {
                      code: response.result.product_data.selling_uom.uom_code,
                      value: response.result.product_data.selling_uom.id,
                      label: response.result.product_data.selling_uom.uom_name,
                    },
                  ]
                : "",

            jain_friendly: [
              {
                value: response.result.product_data.jain_friendly ? 1 : 0,
                label: response.result.product_data.jain_friendly
                  ? "Yes"
                  : "No",
              },
            ],

            taste:
              response.result.product_data?.taste?.length > 0
                ? response.result.product_data.taste.map((item) => ({
                    id: item.id,
                    value: item.taste.id,
                    label: item.taste.taste_name,
                  }))
                : [],

            tax:
              response.result.product_data?.tax?.length > 0
                ? response.result.product_data.tax.map((item) => ({
                    id: item.id,
                    value: item.tax.id,
                    label: item.tax.tax_name,
                    rate: item.tax.tax_rate,
                  }))
                : [],

            ingredients: response.result.product_data?.ingredients,

            nutrition_details: generateNutritionDetails(
              response.result.product_data,
              response.result.nutrients_data,
              response.result.uom_data
            ),

            barcode_type:
              response.result.product_data &&
              response.result.product_data.barcode_type
                ? [
                    {
                      value: response.result.product_data.barcode_type.id,
                      label:
                        response.result.product_data.barcode_type.barcode_type,
                    },
                  ]
                : "",

            price: constructStorePrices(
              response.result.product_data,
              response.result.store_data,
              response.result.discount_type_data
            ),

            minimum_selling_quantity: constructMSQ(
              response.result.product_data,
              response.result.store_data
            ),

            is_active: [
              {
                value: response.result.product_data.is_active ? 1 : 0,
                label: response.result.product_data.is_active
                  ? "Active"
                  : "Inactive",
              },
            ],
          };

          if (editOptions.extraAction) {
            editOptions.extraAction(editedValues);
          }

          if (editOptions.setInitialData) {
            editOptions.setInitialData({
              ingredients: editedValues.ingredients,
              tax: editedValues.tax,
              taste: editedValues.taste,
            });
          }

          dispatch(setProductToEdit(editedValues));
        } else {
          editOptions.props.setFieldValue("price", [
            {
              batch_name: `Batch ${makeid(10)}`,
              store_prices: response.result.store_data.map((branch) => ({
                store: branch.store_name,
                mrp: 0,
                selling_discount: 0,
                selling_price: 0,
                minimum_selling_quantity: 0,
                selling_discount_type: "",
                ceil: true,
              })),
              bulk_mrp: "",
              bulk_selling_discount: "",
              bulk_selling_price: "",
              bulk_minimum_selling_quantity: "",
              bulk_selling_discount_type: "",
              bulk_ceil: true,
            },
          ]);

          editOptions.props.setFieldValue(
            "minimum_selling_quantity",
            response.result.store_data.map((branch) => ({
              store: branch.store_name,
              days: FORM_CONSTANTS.DAYS_OF_WEEK.reduce((obj, day) => {
                obj[day] = 0;
                return obj;
              }, {}),
            }))
          );
        }
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
        dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "addProduct" }));
      });
  };
}

interface getProductForPOSProps {
  code: string;
  store?: number;
  pos: boolean;
  toggleBatchModal?: any;
  setQuery?: (value) => void;
  addProduct?: (params: ProductType) => void;
  productGroup?: boolean;
}

export function getProductForPOS({
  code,
  store,
  pos = false,
  toggleBatchModal,
  setQuery,
  addProduct,
  productGroup = false,
}: getProductForPOSProps) {
  return async function getProductForPOSThunk(dispatch, getState) {
    dispatch(setProductStatus(STATUSES.LOADING));
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "addProduct" }));
    try {
      const response: any = await getScannedProduct(code, store, pos, true);
      if (Object.keys(response.result).length === 0) {
        setQuery && setQuery({ query: "", type: "product" });
        return Toast("No such Product exists", "warning");
      } else if (addProduct) {
        addProduct(response.result);
      } else if (response.result.length > 1) {
        for (let i in response.result) {
          dispatch(AddPOSProduct(response.result[i]));
        }
      } else {
        if (Array.isArray(response.result.batch)) {
          dispatch(setBatchOptions(response.result));
          toggleBatchModal(true);
        } else {
          dispatch(AddPOSProduct(response.result));
        }
      }
    } catch (error) {
      dispatch(setProductStatus(STATUSES.ERROR));
      dispatch(setProductError(error.message));
    } finally {
      dispatch(setProductStatus(STATUSES.IDLE));
      dispatch(setCommonStatus({ state: STATUSES.IDLE, type: "addProduct" }));
      setQuery && setQuery({ query: "", type: "product" });
    }
  };
}

export function getIndividualProduct({
  id,
  store,
  addProduct,
  manualAdd = false,
  toggleBatchModal,
  barcode = false,
  editOptions = {
    edit: false,
  },
  advanceOrder = {
    quantity: 0,
  },
  productQuantity = 0,
}: {
  id: number;
  store?: number;
  addProduct?: (params: ProductType) => void;
  manualAdd?: boolean;
  toggleBatchModal?: any;
  barcode?: boolean;
  editOptions?: {
    edit: boolean;
    setInitialData?: React.Dispatch<
      React.SetStateAction<{
        ingredients: any[];
        tax: any[];
      }>
    >;
    PropsRef?: any;
  };
  advanceOrder?: {
    quantity: number;
    batch_id?: number;
    product_group?: {
      id: number;
      product_group_code: string;
      product_group_name: string;
      selling_price: number;
      products_quantity: number;
    };
  };
  productQuantity?: number;
}) {
  return async function getIndividualProductThunk(dispatch, getState) {
    const {
      root: {
        branch: { selectedStore },
      },
    }: RootState = getState();
    dispatch(setProductStatus(STATUSES.LOADING));
    dispatch(setCommonStatus({ state: STATUSES.LOADING, type: "addProduct" }));
    getSpecificProduct(id, store)
      .then((response: any) => {
        if (manualAdd) {
          if (response.result.price.length === 1) {
            dispatch(
              AddProduct(
                transformProduct(
                  response.result,
                  selectedStore,
                  productQuantity ? productQuantity : response.result.quantity,
                  {
                    id: 0,
                    product_group_code: "",
                    product_group_name: "",
                    selling_price: 0,
                    products_quantity: 0,
                  }
                ),
                {
                  batch_id: response.result.price[0].id,
                  store_id: response.result.price[0].store_prices[0].store,
                  batch_name: response.result.price[0].batch_name,
                  batch_mrp: response.result.price[0].store_prices[0].mrp,
                  batch_selling_price:
                    response.result.price[0].store_prices[0].selling_price,
                }
              )
            );
          } else {
            dispatch(setProductToEdit(response.result));
            toggleBatchModal(true);
          }
        } else if (barcode) {
          addProduct(response.result);
        } else if (editOptions.edit) {
          // editOptions.PropsRef && editOptions.PropsRef.current.resetForm();
          dispatch(
            FetchProductFormOptionsData({
              productData: response.result,
              editOptions,
            })
          );
        } else if (advanceOrder.batch_id > 0) {
          const batch = response.result.price.find(
            (price) => price.id === advanceOrder.batch_id
          );
          dispatch(
            AddProduct(
              transformProduct(response.result, selectedStore, false, {
                id: 0,
                product_group_code: "",
                product_group_name: "",
                selling_price: 0,
                products_quantity: 0,
              }),
              {
                batch_id: batch.id,
                store_id: batch.store_prices[0].store,
                batch_name: batch.batch_name,
                batch_mrp: batch.store_prices[0].mrp,
                batch_selling_price: batch.store_prices[0].selling_price,
              }
            )
          );
        } else {
          dispatch(setProductToEdit(response.result));
        }
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
        if (barcode) {
          dispatch(
            setCommonStatus({ state: STATUSES.IDLE, type: "addProduct" })
          );
        }
      });
  };
}

interface IProductOnScanProps {
  code: string;
  store?: number;
  pos?: boolean;
  toggleBatchModal?: any;
  setQuery?: (value) => void;
  addProduct?: (params: ProductType) => void;
  avarya_filter?: boolean;
  productGroup?: boolean;
}

export function getProductsOnScan({
  code,
  store,
  pos = false,
  toggleBatchModal,
  setQuery,
  addProduct,
  avarya_filter = false,
  productGroup = false,
}: IProductOnScanProps) {
  return async function getProductsOnScanThunk(dispatch) {
    dispatch(setProductStatus(STATUSES.LOADING));
    getScannedProduct(code, store, pos, avarya_filter)
      .then((response: any) => {
        if (Object.keys(response.result).length === 0) {
          // setQuery && setQuery({ query: "", type: "product" });
          return Toast("No such Product exists", "warning");
        } else if (addProduct) {
          addProduct(response.result);
        } else if (response.result.print_name) {
          const updatedPrices = filterNullElements(
            response.result.price.map((item) => {
              return item.store_prices.length > 0 ? item : null;
            })
          );
          if (updatedPrices.length === 1) {
            dispatch(
              AddProduct(
                transformProduct(
                  response.result,
                  store,
                  response.result.quantity,
                  {
                    id: 0,
                    product_group_code: "",
                    product_group_name: "",
                    selling_price: 0,
                    products_quantity: 0,
                  }
                ),
                {
                  batch_id: updatedPrices[0].id,
                  store_id: updatedPrices[0].store_prices[0].store,
                  batch_name: updatedPrices[0].batch_name,
                  batch_mrp: updatedPrices[0].store_prices[0].mrp,
                  batch_selling_price:
                    updatedPrices[0].store_prices[0].selling_price,
                }
              )
            );
            setQuery && setQuery({ query: "", type: "product" });
          } else {
            dispatch(
              setProductToEdit({ ...response.result, price: updatedPrices })
            );
            toggleBatchModal(true);
          }
        } else if (response.result.print_hamper_name) {
          dispatch(
            getIndividualProductGroup({
              id: response.result.id,
              manualAdd: true,
              selectedStore: store,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(setProductError(error.message));
        dispatch(setProductStatus(STATUSES.ERROR));
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
        // !productGroup && setQuery && setQuery({ query: "", type: "product" });
      });
  };
}

interface ProductFilterProps {
  pageNo?: number;
  query?: string;
  store?: number;
  pos?: boolean;
  setQuery?: (value) => void;
  product?: number;
  product_group?: number;
  toggleBatchModal?: any;
  addProduct?: (value) => void;
  avarya_filter?: boolean;
  productGroup?: boolean;
}

export function getProductsForSpecificStore({
  pageNo,
  query,
  store,
  pos = false,
  product,
  product_group,
  toggleBatchModal,
  avarya_filter = false,
  addProduct,
  setQuery,
  productGroup = false,
}: ProductFilterProps) {
  return async function getStoreSpecificProductsThunk(
    dispatch: AppDispatch,
    getState: any
  ) {
    const {
      common: { controller: abortController },
    }: RootState["root"] = getState().root;

    abortController?.abort();

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    dispatch(setProductStatus(STATUSES.LOADING));
    getStoreSpecificProduct({
      pageNo,
      query,
      store,
      pos,
      product,
      product_group,
      avarya_filter,
      controller,
    })
      .then((response: any) => {
        if (response.result.length === 0) {
          // setQuery && setQuery({ query: "", type: "product" });
          dispatch(setProductStatus(STATUSES.IDLE));
          dispatch(clearProducts());
          return Toast("No such Product exists", "warning");
        } else if (product && Object.keys(response.result).length > 0) {
          const updatedPrices = filterNullElements(
            response.result.price.map((item) => {
              return item.store_prices.length > 0 ? item : null;
            })
          );
          if (updatedPrices.length === 1) {
            dispatch(
              AddProduct(
                transformProduct(
                  response.result,
                  store,
                  response.result.quantity ? response.result.quantity : 0,
                  {
                    id: 0,
                    product_group_code: "",
                    product_group_name: "",
                    selling_price: 0,
                    products_quantity: 0,
                  }
                ),
                {
                  batch_id: updatedPrices[0].id,
                  store_id: updatedPrices[0].store_prices[0].store,
                  batch_name: updatedPrices[0].batch_name,
                  batch_mrp: updatedPrices[0].store_prices[0].mrp,
                  batch_selling_price:
                    updatedPrices[0].store_prices[0].selling_price,
                }
              )
            );
          } else {
            dispatch(
              setProductToEdit({
                ...response.result,
                price: updatedPrices,
              })
            );
            toggleBatchModal(true);
          }
        } else if (product_group && Object.keys(response.result).length > 0) {
          if (addProduct) {
            addProduct(response.result);
          } else {
            for (let i = 0; i < response.result.products.length; i++) {
              dispatch(
                AddProduct(
                  transformProduct(
                    response.result.products[i],
                    store,
                    response.result.products[i].quantity,
                    {
                      id: response.result.id,
                      product_group_code: response.result.hamper_code,
                      product_group_name: response.result.print_hamper_name,
                      selling_price:
                        response.result.products[i].price.store_prices[0]
                          .selling_price,
                      products_quantity: response.result.count,
                    }
                  ),
                  {
                    batch_id: response.result.products[i].price.id,
                    store_id:
                      response.result.products[i].price.store_prices[0].store,
                    batch_name: response.result.products[i].price.batch_name,
                    batch_mrp:
                      response.result.products[i].price.store_prices[0].mrp,
                    batch_selling_price:
                      response.result.products[i].price.store_prices[0]
                        .selling_price,
                  }
                )
              );
            }
          }
        } else if (response.result.length > 0) {
          dispatch(getAllProducts(response.result));
          dispatch(setProductStatus(STATUSES.IDLE));
          return;
        } else if (response.result.results.length > 0) {
          dispatch(getAllProducts(response.result.results));
        }
        dispatch(setProductStatus(STATUSES.IDLE));
      })
      .catch((error) => {
        if (error.message === "canceled") {
          dispatch(setProductStatus(STATUSES.LOADING));
          return;
        }

        dispatch(setProductError(error.message));
        dispatch(setProductStatus(STATUSES.ERROR));
      });
  };
}

export function addNewProduct(
  productParams: ProductType,
  actions: FormikHelpers<ProductType>,
  navigate: NavigateFunction
) {
  return async function addNewProductThunk(dispatch) {
    dispatch(setProductStatus(STATUSES.LOADING));
    setProduct(productParams)
      .then((response: any) => {
        dispatch(addProduct(response.result));
        navigate(-1);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}

export function updateExistingProduct(
  id: number,
  productParams: ProductType,
  actions: FormikHelpers<ProductType>,
  navigate: NavigateFunction,
  extra: () => void
) {
  return async function updateExistingProductThunk(dispatch) {
    dispatch(setProductStatus(STATUSES.LOADING));
    updateProduct(id, productParams)
      .then((response: any) => {
        dispatch(editProduct(response.result));
        navigate(-1);
        actions.resetForm();
        actions.setSubmitting(false);
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        if (error === "Product Code Already Exists") {
          actions.setFieldError("product_code", error);
          extra && extra();
          // dispatch(setcommonError({ product_code: error }));
        } else {
          dispatch(setProductStatus(STATUSES.ERROR));
          dispatch(setProductError(error.message));
          dispatch(setcommonError(error.message));
          Toast(`${error.message}`, "error");
        }
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProduct(id: number) {
  return async function deleteProductThunk(dispatch) {
    dispatch(setProductStatus(STATUSES.LOADING));
    delProduct(id)
      .then((response: any) => {
        dispatch(editProduct(response.result));
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductNutritionMapping(id: number) {
  return async function deleteProductNutritionMappingThunk(dispatch) {
    delProductNutritionMapping(id)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductTaxMapping(id: number) {
  return async function deleteProductTaxMappingThunk(dispatch) {
    delProductTaxMapping(id)
      .then((response: any) => {
        // Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}

export function deleteProductBatchMapping(id: number, add: boolean = false) {
  return async function deleteProductBatchMappingThunk(dispatch) {
    delProductBatchMapping(id, add)
      .then((response: any) => {
        Toast(`${response.message}`, "success");
      })
      .catch((error) => {
        dispatch(setProductStatus(STATUSES.ERROR));
        dispatch(setProductError(error.message));
        // Toast(`${error.message}`, "error");
      })
      .finally(() => {
        dispatch(setProductStatus(STATUSES.IDLE));
      });
  };
}
