import { Card, CardBody, CustomMultiSelect } from "components/core-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  clearProducts,
  getProductsForSpecificStore,
  getProductsOnScan,
} from "store/ManageProducts/productSlice";
import {
  GENERAL_CONSTANTS,
  ORDER_CONSTANTS,
  POS_HEADER_CONSTANTS,
  STATUSES,
  TOAST_CONSTANTS,
  TPOSHeaderQuery,
  Toast,
  constructProductOptions,
  containsNumbers,
  debounce,
  financialYear,
  isBarcode,
  onlyNumbers,
  useAppDispatch,
  useAppSelector,
} from "utils";
import {
  clearCustomers,
  getCustomerOrderDetails,
  getCustomers,
  getIndividualCustomer,
} from "store/ManageCustomers/customerSlice";
import { ChooseBatchModal } from "./ChooseBatchModal";
import { AddCustomerModal } from "./AddCustomerModal";
import { FilterOrder } from "store/POS";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "components/ConfirmModal";

/**
 * POSHeader component represents the header section of the Point of Sale (POS) page.
 * It displays various options and functionalities related to the POS system.
 *
 * @component
 * @param {Object} focusRef - The reference to the focus element.
 * @returns {JSX.Element} The rendered POSHeader component.
 */

const POSHeader = ({ focusRef }) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const {
    branch: { branch: stores, selectedStore },
    cart: { cart },
    common: { status: commonStatus },
    customer: {
      customers: customerData,
      customerToEdit: customer_details,
      status: customerStatus,
    },
    product: { products: productData, status: productStatus },
  } = useAppSelector((state) => state.root);

  // Add New customer Modal
  const [addNewCustomer, setAddNewCustomer] = useState<{
    state: boolean;
    customer_data: any;
  }>({ state: false, customer_data: null });

  // Batch Modal if product has multiple batches
  const [batchModal, setBatchModal] = useState<boolean>(false);

  // Confirm Load Order Modal
  const [confirmLoadOrder, setConfirmLoadOrder] = useState<boolean>(false);

  const [query, setQuery] = useState<{
    query: string;
    type: TPOSHeaderQuery;
  }>({
    query: GENERAL_CONSTANTS.EMPTY_STRING,
    type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
  });

  const toggleBatchModal = (value: boolean) => {
    setBatchModal(value);
  };

  // Store code for the selected store
  const store_code = selectedStore
    ? stores?.find((store) => store?.id === selectedStore)?.store_code
    : null;

  const getProductOptions: any[] = useMemo(() => {
    return constructProductOptions(productData);
  }, [productData?.length]);

  const getCustomerOptions = useMemo(() => {
    return customerData && customerData?.length > 0
      ? customerData.map((item, index) => {
          return {
            value: item.contact_number,
            label: `${item.name} ${
              item?.contact_number?.toString()?.length > 0
                ? `| ${item.contact_number}`
                : ""
            }`,
            id: item.id,
          };
        })
      : [];
  }, [customerData?.length, customerStatus]);

  const MemoizedMultiSelect = useMemo(() => {
    return CustomMultiSelect;
  }, [CustomMultiSelect]);

  //Function for calling dispatch for different types of search (Barcode, Product, Order, Customer)
  const searchValues = (query) => {
    if (!selectedStore) {
      // If store is not selected
      return Toast(
        POS_HEADER_CONSTANTS.SELECT_STORE_MESSAGE,
        TOAST_CONSTANTS.ERROR
      );
    }
    if (query.type === POS_HEADER_CONSTANTS.PRODUCT_QUERY) {
      if (!isBarcode(query.query) && query.query.length >= 3) {
        // If query is not barcode and length is greater than 3

        dispatch(
          getProductsForSpecificStore({
            pageNo: 1,
            setQuery: setQuery,
            query: query.query,
            store: selectedStore,
            pos: true,
          })
        );
      } else if (isBarcode(query.query) && query.query.length > 3) {
        dispatch(
          getProductsOnScan({
            code: encodeURI(query.query),
            store: selectedStore,
            pos: true,
            toggleBatchModal: toggleBatchModal,
            setQuery: setQuery,
          })
        );
        // If query is barcode and length is greater than 3

        //Clear options after search
        dispatch(clearProducts());
      }
    } else if (query.type === POS_HEADER_CONSTANTS.ORDER_QUERY) {
      dispatch(
        FilterOrder({
          pageNo: 1,
          query: GENERAL_CONSTANTS.EMPTY_STRING,
          store: selectedStore,
          code: `${store_code}/${financialYear(new Date())}/${query.query}`,
          setQuery: setQuery,
          navigate: navigate,
        })
      );
    } else if (query.type === POS_HEADER_CONSTANTS.CUSTOMER_QUERY) {
      // If the query is for customer search
      if (
        onlyNumbers(query.query)
          ? query.query.length === 10
          : query.query.length >= 3
      ) {
        dispatch(
          getCustomers(
            false,
            1,
            query.query,
            true,
            setQuery,
            setAddNewCustomer,
            focusRef
          )
        );
      }
    } else {
      return;
    }
  };

  //Debounce function for search
  const optimizeSearch = useCallback(debounce(searchValues), [selectedStore]);

  useEffect(() => {
    dispatch(getIndividualCustomer(55));
  }, []);

  return (
    <Card cardClassNames="my-2">
      <CardBody bodyClassNames="px-2 py-0">
        <div className="row overflow-hidden">
          <div className="col-sm-4">
            <CustomMultiSelect
              disabled={!selectedStore}
              tabIndex={1}
              selectRef={focusRef}
              select={false}
              isLoading={productStatus === STATUSES.LOADING}
              placeholder={POS_HEADER_CONSTANTS.SCAN_PRODUCT_PLACEHOLDER}
              options={getProductOptions}
              value={null}
              onChangeHandler={(e) => {
                setQuery &&
                  setQuery({
                    query: GENERAL_CONSTANTS.EMPTY_STRING,
                    type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
                  });
                if (e && e.value && e.type === "product") {
                  dispatch(
                    getProductsForSpecificStore({
                      store: selectedStore,
                      pos: true,
                      product: e.value,
                      toggleBatchModal: toggleBatchModal,
                    })
                  );
                } else if (e && e.value && e.type === "productGroup") {
                  dispatch(
                    getProductsForSpecificStore({
                      store: selectedStore,
                      pos: true,
                      product_group: e.value,
                    })
                  );
                }
                dispatch(clearProducts());
              }}
              //On key down event for enter key
              onKeyDownHandler={(e) => {
                if (e.key === GENERAL_CONSTANTS.ENTER) {
                  // e.preventDefault();
                  e.stopPropagation();
                  if (isBarcode(query.query)) {
                    dispatch(
                      getProductsOnScan({
                        code: encodeURI(query.query),
                        store: selectedStore,
                        pos: true,
                        toggleBatchModal: toggleBatchModal,
                        setQuery: setQuery,
                      })
                    );
                  } else {
                    optimizeSearch(query);
                  }
                }
              }}
              inputValue={
                query.type === POS_HEADER_CONSTANTS.PRODUCT_QUERY
                  ? query.query
                  : GENERAL_CONSTANTS.EMPTY_STRING
              }
              onInputChangeHandler={(e) => {
                if (e.length === 0) {
                  dispatch(clearProducts());
                }
                // if (!isBarcode(e) && e.length >= 3) {
                if (!containsNumbers(e.substring(0, 3))) {
                  optimizeSearch({
                    query: e,
                    type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
                  });
                }
                setQuery({
                  query: e,
                  type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
                });
              }}
              menuCloseHandler={() => {
                dispatch(clearProducts());
              }}
            />
          </div>
          <div className="col-sm-4">
            <CustomMultiSelect
              tabIndex={2}
              select={false}
              isLoading={customerStatus === STATUSES.LOADING}
              inputValue={
                query.type === POS_HEADER_CONSTANTS.CUSTOMER_QUERY
                  ? query.query
                  : GENERAL_CONSTANTS.EMPTY_STRING
              }
              placeholder={POS_HEADER_CONSTANTS.CUSTOMER_PLACEHOLDER}
              options={getCustomerOptions}
              value={
                customer_details && customer_details.id
                  ? {
                      value: customer_details?.id,
                      label: customer_details?.name,
                    }
                  : {
                      value: 0,
                      label: GENERAL_CONSTANTS.LOADING,
                    }
              }
              onKeyDownHandler={(e) => {
                if (e.key === GENERAL_CONSTANTS.ENTER) {
                  if (
                    onlyNumbers(query.query)
                      ? query.query.length === 10
                      : query.query.length >= 3
                  ) {
                    e.stopPropagation();
                    optimizeSearch(query);
                  }

                  if (onlyNumbers(query.query)) {
                    if (query.query.length > 10 || query.query.length < 10) {
                      Toast("Invalid Contact Number", TOAST_CONSTANTS.WARNING);
                      return;
                    }
                  }
                }
              }}
              onInputChangeHandler={(e) => {
                if (e.length === 0) {
                  dispatch(clearCustomers());
                }

                if (!onlyNumbers(e) && e.length >= 3) {
                  optimizeSearch({
                    query: e,
                    type: POS_HEADER_CONSTANTS.CUSTOMER_QUERY,
                  });
                }

                setQuery({
                  query: e,
                  type: POS_HEADER_CONSTANTS.CUSTOMER_QUERY,
                });
              }}
              onChangeHandler={(e, actions) => {
                if (e !== null || e !== undefined) {
                  focusRef.current.focus();
                  e && e.id && dispatch(getIndividualCustomer(e.id));
                  e && e.id && dispatch(getCustomerOrderDetails(e.id));
                  setQuery({
                    query: GENERAL_CONSTANTS.EMPTY_STRING,
                    type: POS_HEADER_CONSTANTS.PRODUCT_QUERY,
                  });
                }
                if (actions.action === GENERAL_CONSTANTS.CLEAR) {
                  customer_details &&
                    customer_details.id !== 55 &&
                    dispatch(getIndividualCustomer(55));
                  dispatch(clearCustomers());
                }
              }}
              menuCloseHandler={() => {
                dispatch(clearCustomers());
                setQuery({
                  query: GENERAL_CONSTANTS.EMPTY_STRING,
                  type: POS_HEADER_CONSTANTS.CUSTOMER_QUERY,
                });
              }}
            />
            {/* Add new customer modal */}
            {addNewCustomer.state && (
              <AddCustomerModal
                focusRef={focusRef}
                modal={addNewCustomer}
                setModal={setAddNewCustomer}
              />
            )}
          </div>
          <div className="col-sm-4">
            <MemoizedMultiSelect
              tabIndex={3}
              select={false}
              isLoading={
                commonStatus.state === STATUSES.LOADING &&
                commonStatus.type === ORDER_CONSTANTS.FETCH_ORDER_LOADING_STATE
              }
              placeholder={POS_HEADER_CONSTANTS.ORDER_PLACEHOLDER}
              options={GENERAL_CONSTANTS.EMPTY_ARRAY}
              inputValue={
                query.type === POS_HEADER_CONSTANTS.ORDER_QUERY ||
                query.type === POS_HEADER_CONSTANTS.ADVANCE_ORDER_QUERY
                  ? query.query
                  : GENERAL_CONSTANTS.EMPTY_STRING
              }
              onKeyDownHandler={(e) => {
                if (e.key === GENERAL_CONSTANTS.ENTER) {
                  cart.cartItems.length > 0
                    ? setConfirmLoadOrder(true)
                    : optimizeSearch(query);
                }
              }}
              inputAppendTxt={
                store_code ? `${store_code}/${financialYear(new Date())}/` : ""
              }
              onInputChangeHandler={(e) => {
                if (onlyNumbers(e)) {
                  setQuery({
                    query: e,
                    type: POS_HEADER_CONSTANTS.ORDER_QUERY,
                  });
                }
              }}
            />
          </div>
        </div>
      </CardBody>
      {confirmLoadOrder && (
        <ConfirmModal
          modal={confirmLoadOrder}
          setModal={setConfirmLoadOrder}
          title={GENERAL_CONSTANTS.CONFIRM_MODAL_MESSAGE}
          message={
            <div className="row">
              <p>{POS_HEADER_CONSTANTS.CONFIRM_LOAD_ORDER}</p>
            </div>
          }
          rejectClick={() => {
            confirmLoadOrder &&
              setQuery({
                query: GENERAL_CONSTANTS.EMPTY_STRING,
                type: POS_HEADER_CONSTANTS.ORDER_QUERY,
              });
          }}
          confirmClick={() => {
            if (query.type === POS_HEADER_CONSTANTS.ORDER_QUERY) {
              dispatch(
                FilterOrder({
                  pageNo: 1,
                  query: GENERAL_CONSTANTS.EMPTY_STRING,
                  store: selectedStore,
                  code: `${store_code}/${financialYear(new Date())}/${
                    query.query
                  }`,
                  setQuery: setQuery,
                  navigate: navigate,
                })
              );
            }
            setConfirmLoadOrder(false);
          }}
        />
      )}
      {batchModal && (
        <ChooseBatchModal
          modal={batchModal}
          setModal={setBatchModal}
          focusRef={focusRef}
          setQuery={setQuery}
        />
      )}
    </Card>
  );
};

export { POSHeader };
